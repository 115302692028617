import React, { Component } from "react";

class BikeTechData extends Component {
  render() {
    const height = {
      height: this.props.height.tchDH
    };
    const data = this.props.data;
    return (
      <div className="bikes-section__bike-spec-table-wrapper" style={height}>
        <div className="bikes-section__bike-spec-table-header">
          <h4 className="bikes-section__bike-spec-table-title">{data.title}</h4>
        </div>
        <ul className="bikes-section__bike-spec-table">
          {data.details.map((item, index) => (
            <li className="bikes-section__bike-spec-table-item" key={index}>
              {item.name} <span>{item.value}</span>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

export default BikeTechData;
