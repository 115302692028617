import React, { Component } from "react";
import DakarLogo from "../../images/dakar-logo.svg";
import ZigmasDakarTeamLogo from "../../images/brands/zigmas-dakar-team.svg";
import Countdown from "./Countdown";
import LeftSidebar from "./sidebar/LeftSidebar";
import RightSidebar from "./sidebar/RightSidebar";

class HeroSection extends Component {
  render() {
    const bgUrl = this.props.content.hero_img;
    const bgImg = {
      backgroundImage: `url(${bgUrl})`
    };
    const date = this.props.content.countdown_date;
    return (
      <div className="hero-section__wrapper">
        <div className="hero-section__container" style={bgImg}>
          <img
            className="hero-section__main-partner-logo"
            src={ZigmasDakarTeamLogo}
            alt="Zigmas Dakar Team Logo"
          />
          <div className="hero-section__content">
            <img src={DakarLogo} alt="AGdakar logo" />
            <div className="hero-section__main-msg-wrapper">
              <h1 className="hero-section__main-msg">
                {this.props.content.main_message}
              </h1>
              <a
                href={this.props.content.cta_url}
                className="hero-section__cta button button--green"
              >
                {this.props.content.cta}
              </a>
            </div>
          </div>
          {/* <div className="hero-section__footer">
            <div className="hero-section__footer-content">
              <h5 className="hero-section__footer-title">
                {this.props.content.countdown_text}
              </h5>
              <div className="hero-section__countdown">
                <Countdown date={`${date}`} />
              </div>
            </div>
          </div> */}
        </div>
        <div className="sidebar sidebar--left">
          <LeftSidebar />
        </div>
        <div className="sidebar sidebar--right">
          <RightSidebar slogan={this.props.content.page_slogan} />
        </div>
      </div>
    );
  }
}

export default HeroSection;
