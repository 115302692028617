import React, { Component } from "react";
import Container from "@material-ui/core/Container";
import PlayCircleOutlineOutlined from "@material-ui/icons/PlayCircleOutlineOutlined";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import YouTube from "react-youtube";
import CloseRounded from "@material-ui/icons/CloseRounded";
import { ReactComponent as YoutubeLogo } from "../../../images/soc-media-logo/youtube-logo.svg";
import Slider from "react-slick";

const pathArray = window.location.pathname.split("/");
const firstPath = pathArray[1];

export default class YoutubeFeed extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slides: this.props.data.length,
    };
  }

  render() {
    const feed = this.props.data;
    const rows = feed.length / 3

    const settings = {
      dots: true,
      infinite: false,
      arrows: false,
      autoplay: false,
      autoplaySpeed: 3000,
      slidesToShow: 3,
      rows: rows,
      variableWidth: false,
      className: "socmedia-section__feed-slider",
      responsive: [
        {
          breakpoint: 1280,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            rows: 1,
          }
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            rows: 1,
          }
        }
      ]
    }
    return (
      <Container className="youtubefeed__container" maxWidth="lg">
        <Slider {...settings}>
          {feed.map((item, index) => (
            <div className="youtubefeed__video-img-wrapper">
              <Aas data={item} key={index} />
            </div>
          ))}
        </Slider>
      </Container>
    );
  }
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class Aas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }

  handleOpen = () => {
    this.setState({
      open: true
    });
  };

  handleClose = () => {
    this.setState({
      open: false
    });
  };

  videoEnded = () => {
    this.setState({
      open: false
    });
  };

  render() {
    const thumbnailUrl = this.props.data.thumbnailHigh;
    const videoId = this.props.data.videoId;
    const videoTitle = this.props.data.title;
    const date = this.props.data.publishedAt,
      cdate = new Date(date),
      dayNamesLT = [
        "Sau",
        "Vas",
        "Kov",
        "Bal",
        "Geg",
        "Bir",
        "Lie",
        "Rgp",
        "Rgs",
        "Spl",
        "Lap",
        "Grd"
      ],
      dayNamesEN = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"
      ],
      month = firstPath === "en" ? dayNamesEN[cdate.getMonth()] : dayNamesLT[cdate.getMonth()],
      year = cdate.getFullYear(),
      day = cdate.getDate();

    const opts = {
      height: "947",
      width: "1920",
      playerVars: {
        frameborder: 0,
        autoplay: 1,
        controls: 1,
        modestbranding: 0,
        disablekb: 0,
        rel: 0,
        fs: 1
      }
    };


    return (
      <div className="youtubefeed__video-card">
        <div className="youtubefeed__video-card-content">
          <div
            className="youtubefeed__video-thumbnail-wrapper"
          >
            <PlayCircleOutlineOutlined
              className="youtubefeed__video-play-btn"
              onClick={this.handleOpen}
            />
            <div className="youtubefeed__video-footer-wrapper">
              <h5 className="youtubefeed__video-title">{videoTitle}</h5>
            </div>
            <img
              className="youtubefeed__video-thumbnail"
              src={thumbnailUrl}
              onClick={this.handleOpen}
              alt={videoTitle}
            />
          </div>
          <div className="socialfeed-section__card-header">
            <a href="https://www.youtube.com/channel/UC8HFtm9EVlGwddafi3qON5Q" target="_blank" rel="noopener noreferrer">
              <YoutubeLogo
                className="soc-icon youtube-logo"
                height="24px"
                width="24px"
              />
            </a>
            <p className="socialfeed-section__card-header-date">
              {year} {month} {`${day} d.`}
            </p>
          </div>
        </div>

        <Dialog
          fullScreen
          open={this.state.open}
          onClose={this.handleClose}
          TransitionComponent={Transition}
        >
          <div className="biography-video">
            <div className="biography-video__dialog-header">
              <i onClick={this.handleClose} className="icon-wrapper">
                <CloseRounded className="close-icon" />
              </i>
            </div>
            <YouTube
              containerClassName="biography-video__wrapper biography-video__wrapper--fullwidth"
              videoId={videoId}
              id="video"
              opts={opts}
              onEnd={this.videoEnded}
            />
          </div>
        </Dialog>
      </div>
    );
  }
}
