import React, { Component } from "react";
import axios from "axios";
import HeroSection from "./components/hero-section/HeroSection";
import Biography from "./components/biography/Bio";
import Partners from "./components/partners/partners";
import Products from "./components/products/Products";
import BikesSection from "./components/bikes-section/BikesSection";
import Faq from "./components/faq/Faq";
import SocialFeed from "./components/social-feed/SocialFeed";

const pathArray = window.location.pathname.split("/");
const firstPath = pathArray[1];

class App extends Component {
  constructor() {
    super();
    this.state = {
      done: false,
      socMenu: [],
      faqCat: [],
      faqQuest: [],
      heroContent: [],
      aboutContent: [],
      partnersContent: [],
      partnersLogos: [],
      motorcycleSection: [],
      motorcyclesCat: [],
      motorcycles: [],
      faqDescription: [],
      faqTitle: [],
      products: []
    };
  }

  componentDidMount() {
    this.getJson();
  }

  getJson() {
    const a = firstPath === "en" ? "en/" : "";
    const url = `https://agdakar.lt/${a}wp-json/agdakar/v1/home-page`;
    axios
      .get(url)
      .then(res => {
        const data = res.data;
        this.setState({
          heroContent: data.hero_section,
          aboutContent: data.about_section,
          partnersContent: {
            sectionTitle: data.partners_section.section_title,
            generalPartner: data.partners_section.general_partner,
            ctaText: data.partners_section.cta_text,
            ctaUrl: data.partners_section.cta_url,
            description: data.partners_section.description
          },
          partnersLogos: data.partners_section.partners,
          faqCat: data.faq.categories,
          faqQuest: data.faq.questions,
          faqTitle: data.faq.title,
          faqDescription: data.faq.description,
          motorcycleSection: data.motorcycles_section,
          motorcyclesCat: data.motorcycles_section.categories,
          motorcycles: data.motorcycles_section.motorcycles,
          products: data.products_section,
          done: true
        });
      })
      .catch(err => {
        console.log(err);
      });
  }

  render() {
    if (!this.state.done) {
      return (
        <div>
          <section id="hero-section"></section>
          <section id="biography-section"></section>
          <section id="products-section"></section>
          <section id="partners-section"></section>
          <section id="faq-section"></section>
          <section id="socialfeed-section"></section>
        </div>
      );
    } else {
      return (
        <div>
          <section id="hero-section">
            <HeroSection content={this.state.heroContent} />
          </section>
          <section id="biography-section">
            <Biography content={this.state.aboutContent} />
          </section>
          <BikesSection
            content={this.state.motorcycleSection}
            motorcycles={this.state.motorcycles}
            categories={this.state.motorcyclesCat}
          />
          <section id="products-section">
            <Products products={this.state.products} />
          </section>
          <section id="partners-section">
            <Partners
              logos={this.state.partnersLogos}
              content={this.state.partnersContent}
            />
          </section>
          <section id="faq-section">
            <Faq
              faqCat={this.state.faqCat}
              faqQuest={this.state.faqQuest}
              title={this.state.faqTitle}
              description={this.state.faqDescription}
            />
          </section>
          <section id="socialfeed-section">
            <SocialFeed />
          </section>
        </div>
      );
    }
  }
}

export default App;
