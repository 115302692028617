import React, { Component } from "react";
import Container from "@material-ui/core/Container";
import background from "../../images/partners_bg.jpg";
import Slider from "react-slick";

const sectionStyle = {
  backgroundImage: `url(${background})`
};

class Logos extends Component {
  render() {
    return (
      <img src={this.props.data} className="partners-section__logo" alt="ag" />
    );
  }
}

class Partners extends Component {

  constructor(props) {
    super(props);
    this.state = {
      slides: this.props.logos.length,
      width: window.innerWidth
    };
  }

  updateDimensions() {
    const update_width = window.innerWidth;
    this.setState({ width: update_width });
  }

  componentDidMount() {
    this.updateDimensions();
    this.productsPerRow();
    window.addEventListener("resize", this.updateDimensions.bind(this));
    window.addEventListener("resize", this.productsPerRow.bind(this));
  }


  productsPerRow() {
    if (this.state.width >= 1280) {
      this.setState({
        slides: this.props.logos.length,
      });
    } else if (this.state.width >= 768) {
      this.setState({
        autoplay: true,
      });
    } else {
      this.setState({
        autoplay: true,
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
    window.removeEventListener("resize", this.productsPerRow.bind(this));
  }

  render() {

    const logos = this.props.logos;
    const content = this.props.content;
    const settings = {
      dots: this.state.dots,
      infinite: true,
      arrows: false,
      autoplay: false,
      speed: 2000,
      // autoplaySpeed: 2000,
      slidesToShow: this.state.slides,
      variableWidth: true,
      className: "partners-section__logos-wrapper",
      responsive: [
        {
          breakpoint: 1280,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
            variableWidth: false,
            autoplay: true,
            pauseOnFocus: true
          }
        }, {
          breakpoint: 900,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: true,
            dots: true,
            variableWidth: false,
            autoplay: true,
            pauseOnFocus: true
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
            autoplay: true,
            variableWidth: false,
            pauseOnFocus: true
          }
        }
      ]
    }
    return (
      <div className="partners-section__wrapper" style={sectionStyle}>
        <div className="partners-section__header">
          <h2 className="partners-section__header-title">{content.sectionTitle}</h2>
          <p>{content.description}</p>
          <a
            href={`mailto:${content.ctaUrl}`}
            className="partners-section__cta link__decorated"
          >
            {content.ctaText}
          </a>
        </div>
        <div className="partners-section__top-shadow"></div>
        <div className="partners-section__container">
          <div className="partners-section__general-partner">
            <img className="partners-section__general-partner-logo" src={content.generalPartner} alt="Zigmas dakar team" />
          </div>
          <Container className="partners-section__logos-container" maxWidth="lg">
            <Slider {...settings}>
              {logos.map((logo, index) => (
                <Logos data={logo.logo_url} key={index} />
              ))}
            </Slider>
          </Container>
        </div>
      </div >
    );
  }
}

export default Partners;
