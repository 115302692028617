import React, { Component } from "react";
import Container from "@material-ui/core/Container";
import Slider from "react-slick";

class Products extends Component {
  constructor() {
    super();
    this.state = {
      title: "",
      content: "",
      slides: 3,
      dots: false,
      width: window.innerWidth
    };
  }

  updateDimensions() {
    const update_width = window.innerWidth;
    this.setState({ width: update_width });
  }

  componentDidMount() {
    this.updateDimensions();
    this.productsPerRow();
    window.addEventListener("resize", this.updateDimensions.bind(this));
    window.addEventListener("resize", this.productsPerRow.bind(this));
  }

  productsPerRow() {
    if (this.state.width >= 1200) {
      this.setState({
        slides: 3,
        dots: false
      });
    } else if (this.state.width >= 768) {
      this.setState({
        slides: 2,
        dots: true
      });
    } else {
      this.setState({
        slides: 1,
        dots: true
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
    window.removeEventListener("resize", this.productsPerRow.bind(this));
  }

  render() {
    const settings = {
      dots: this.state.dots,
      infinite: false,
      arrows: false,
      slidesToShow: this.state.slides,
      className: "products-slider"
    };
    const products = this.props.products.products;
    return (
      <div className="products-section__container">
        <Container maxWidth="md" className="products-section__header">
          <h2 className="products-section__header-title">
            {this.props.products.title}
          </h2>
          <div
            className="products-section__header-content"
            dangerouslySetInnerHTML={{ __html: this.props.products.content }}
          ></div>
        </Container>
        <Container maxWidth="lg">
          <div className="products">
            <Slider {...settings}>
              {products.map((product, index) => (
                <div className="product" key={index}>
                  <div className="product__card">
                    <div className="product__card-header "></div>
                    <div className="product__card-thumbnail ">
                      <a href={product.link}>
                        <img
                          src={product.imageURL}
                          alt={product.title}
                          className="attachment-shop_catalog size-shop_catalog wp-post-image"
                        />
                      </a>
                    </div>
                    <div className="product__card-footer">
                      <div className="product__card-footer-content">
                        <div className="product__card-footer-titel">
                          <h3 className="product-title-link">
                            <a href={product.link}>{product.title}</a>
                          </h3>
                        </div>
                        <div
                          className="product__card-footer-price"
                          dangerouslySetInnerHTML={{ __html: product.price }}
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className="product__actions">
                    <a href={product.link} className="button" rel="nofollow">
                      {product.cta}
                    </a>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </Container>
      </div>
    );
  }
}

export default Products;
