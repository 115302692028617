import React, { Component } from "react";
import MuiExpansionPanel from "@material-ui/core/ExpansionPanel";
import MuiExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import MuiExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Container from "@material-ui/core/Container";
import Slider from "react-slick";

const ExpansionPanel = MuiExpansionPanel;
const ExpansionPanelSummary = MuiExpansionPanelSummary;
const ExpansionPanelDetails = MuiExpansionPanelDetails;

class FaqCategories extends Component {
  constructor() {
    super();
    this.state = {
      setActive: ""
    };
  }


  render() {
    return (
      <div
        id={this.props.category}
        category={this.props.category}
        onClick={this.props.onClick}
        className={`tab-wrapper ${this.props.activeMode ? "active" : ""}`}
        onLoad={() => window.dispatchEvent(new Event('resize'))}
      >
        <span className="tab">{this.props.name}</span>
      </div>
    );
  }
}

class Faq extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedIndex: 0,
      expanded: "",
      category: this.props.faqCat[0].slug,
      setIndex: null
    };
  }

  handleChange = panel => (event, newExpanded) => {
    this.setState({
      expanded: newExpanded ? panel : false
    });
  };

  handleClick(selectedIndex) {
    const selected =
      this.state.selectedIndex === selectedIndex
        ? selectedIndex
        : selectedIndex;
    this.setState({
      selectedIndex: selected
    });
  }


  render() {
    const faqCat = this.props.faqCat;
    const faqQuest = this.props.faqQuest;
    const filteredData = faqQuest.filter(
      quest => quest.cat_slug === this.state.category
    );

    const settings = {
      dots: false,
      infinite: false,
      arrows: false,
      slidesToShow: 4,
      variableWidth: true,
      className: "faq_categories-slider",
      responsive: [
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          }
        }
      ]
    }

    return (
      <div className="faq-section__container">
        <Container maxWidth="md" className="faq-section__header">
          <h2 className="faq-section__header-title">{this.props.title}</h2>
        </Container>
        <div className="faq-section__categories-wrapper">
          <div className="nav__container">
            <div className="faq_categories">
              <Slider {...settings}>
                {faqCat.map((cat, index) => (
                  <FaqCategories
                    id={cat.id}
                    activeMode={this.state.selectedIndex === index}
                    onClick={() => {
                      this.handleClick(index);
                      this.setState({
                        category: cat.slug
                      });
                    }}
                    name={cat.name}
                    key={index}
                  />
                ))}
              </Slider>
              {/* {faqCat.map((cat, index) => (
                  <FaqCategories
                    id={cat.id}
                    activeMode={this.state.selectedIndex === index}
                    onClick={() => {
                      this.handleClick(index);
                      this.setState({
                        category: cat.slug
                      });
                    }}
                    name={cat.name}
                    key={index}
                  />
                ))} */}
            </div>
          </div>
        </div>
        <div className="faq-section__questions-wrapper">
          <Container className="faq-section__questions">
            {filteredData.map((quest, index) => (
              <ExpansionPanel
                className="faq-section__question"
                expanded={this.state.expanded === quest.id}
                onChange={this.handleChange(quest.id)}
                key={index}
              >
                <ExpansionPanelSummary
                  aria-controls="panel1d-content"
                  className="faq-section__question-header"
                >
                  <h4 className="faq-section__question-title">{quest.title}</h4>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className="faq-section__question-content">
                  <div dangerouslySetInnerHTML={{ __html: quest.content }} />
                </ExpansionPanelDetails>
              </ExpansionPanel>
            ))}
          </Container>
        </div>
      </div>
    );
  }
}

export default Faq;
