import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Drawer from "@material-ui/core/Drawer";
import React, { Component } from "react";
import BioImg from "./BioImg";
import CloseRounded from "@material-ui/icons/CloseRounded";


class Biography extends Component {
  constructor() {
    super();
    this.state = {
      left: false
    };
  }

  render() {
    const toggleDrawer = (side, open) => event => {
      if (
        event.type === "esc" &&
        (event.key === "Tab" || event.key === "Shift")
      ) {
        return;
      }
      this.setState({ [side]: open });
    };
    const achievements = this.props.content.achievement__entries;



    return (
      <Container className="biography__container" maxWidth="xl">
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={3}
          className="biography__content-wrapper"
        >
          <Grid item xs={12} md={10} lg={6}>
            <BioImg
              cta={this.props.content.cta}
              videoId={this.props.content.video_id}
              videoCover={this.props.content.video_cover}
            />
          </Grid>
          <Grid item sm={10} md={10} lg={5}>
            <div className="biography__content">
              <div
                className="biography__content-text"
                dangerouslySetInnerHTML={{ __html: this.props.content.content }}
              ></div>
              <span
                onClick={toggleDrawer("left", true)}
                className="biography__content-cta button button--green "
              >
                {this.props.content.achievements_cta_text}
              </span>
            </div>
          </Grid>
        </Grid>
        <Drawer
          className="biography__achievements-wrapper"
          anchor="left"
          open={this.state.left}
          onClose={toggleDrawer("left", false)}
        >
          <div
            className="biography__achievements-timeline-wrapper"
            role="presentation"
            onKeyDown={toggleDrawer("left", false)}
          >
            <div className="biography__achievements-timeline-header">
              <h3 className="biography__achievements-timeline-title">
                {this.props.content.achievements_cta_text}
              </h3>
              <CloseRounded className="close-icon" onClick={toggleDrawer("left", false)} />
            </div>
            <ul className="biography__achievements-timeline">
              {achievements.map((entry, index) => (
                <li className="biography__achievements-timeline-entry" key={index}>
                  <h3 className="biography__achievements-timeline-year">
                    {entry.year}
                  </h3>
                  <ul className="biography__achievements">
                    {entry.achievements.map((achievement, index) => (
                      <li className="biography__achievement" key={index}>{achievement}</li>
                    ))}
                  </ul>
                </li>
              ))}
            </ul>
          </div>
        </Drawer>
      </Container>
    );
  }
}

export default Biography;
