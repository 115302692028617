import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import ReactDOM from "react-dom";
import BikeTechData from "../bikes-section/BikeTechData";
import Drawer from "@material-ui/core/Drawer";
import Slider from "react-slick";
import CloseRounded from "@material-ui/icons/CloseRounded";
import ArrowForward from "@material-ui/icons/ArrowForward";
import ArrowBack from "@material-ui/icons/ArrowBack";


class BikeCategories extends Component {
  constructor() {
    super();
    this.state = {
      setActive: ""
    };
  }

  render() {
    return (
      <li
        id={this.props.category}
        category={this.props.category}
        onClick={this.props.onClick}
        className={`nav__item ${this.props.activeMode ? "active" : ""}`}
      >
        <span className="nav__link">{this.props.name}</span>
      </li>
    );
  }
}

class BikesSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: "",
      dekoHeight: "",
      height: "",
      tchDH: "",
      right: false,
      arrows: true,
      category: props.categories[0].slug,
      selectedIndex: 0,
      setIndex: null,
      gallery: [],
    };
  }

  componentDidMount() {
    this.getHeight()
    this.updateDimensions();
    window.addEventListener("resize", this.getHeight.bind(this));
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }

  componentWillUnmount() {
    window.addEventListener("resize", this.getHeight.bind(this));
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }


  updateDimensions() {
    const update_width = window.innerWidth;
    this.setState({ width: update_width });
  }


  getHeight() {
    setTimeout(() => {
      const bikeContainerH = ReactDOM.findDOMNode(
        this.refs["bikes-section__bike-container"]
      ).getBoundingClientRect().height;

      const techExcerpH = ReactDOM.findDOMNode(
        this.refs["bikes-section__technical-data-excerpt"]
      ).getBoundingClientRect().height;

      const sectionBgH = bikeContainerH - techExcerpH;
      const techDataH = sectionBgH - 100;

      if (this.state.width >= 1279) {
        this.setState({
          dekoHeight: sectionBgH - 33,
          arrows: true,
          right: false
        });
      } else if (this.state.width >= 500) {
        this.setState({
          dekoHeight: sectionBgH - 81,
          arrows: false,
        });
      } else {
        this.setState({
          dekoHeight: sectionBgH - 89,
          arrows: false,
        });
      }

      this.setState({ height: sectionBgH, tchDH: techDataH });
    }, 300)
  }



  handleClick(selectedIndex) {
    const selected =
      this.state.selectedIndex === selectedIndex
        ? selectedIndex
        : selectedIndex;
    this.setState({
      selectedIndex: selected
    });
  }

  handleNext = () => {
    this.setState({
      activeStep: this.state.activeStep + 1
    });
  };

  handleBack = () => {
    this.setState({
      activeStep: this.state.activeStep - 1
    });
  };

  render() {
    const height = {
      height: this.state.dekoHeight
    };

    const bgStyle = {
      backgroundImage: `url(${this.props.content.background})`,
      height: this.state.height
    };

    const toggleDrawer = (side, open) => event => {
      if (
        event.type === "esc" &&
        (event.key === "Tab" || event.key === "Shift")
      ) {
        return;
      }
      this.setState({ [side]: open });
    };


    const categories = this.props.categories;
    const motorcycles = this.props.motorcycles;
    const filteredData = motorcycles.filter(
      motorcycle => motorcycle.cat_slug === this.state.category
    );

    return (

      <section id="bikes-section">
        <Container maxWidth="md" className="bikes-section__section-header">
          <h2 className="bikes-section__section-header-title">{this.props.content.title} </h2>
          <p className="bikes-section__section-header-content section-header-content">{this.props.content.description}</p>
        </Container>

        <div className="bikes-section__wrapper">
          <div className="bikes-section__background" style={bgStyle} />
          <Container maxWidth="xl" className="bikes-section__bikes-container">
            <div className="bikes-section__decoratio" style={height} />
            <div className="bikes-section__categories-wrapper">
              <nav className="nav nav--bike-cat-nav">
                <div className="nav__container">
                  <ul id="bike_categories" className="nav__items">
                    {categories.map((cat, index) => (
                      <BikeCategories
                        activeMode={this.state.selectedIndex === index}
                        onClick={() => {
                          this.handleClick(index);
                          this.setState({
                            category: cat.slug,
                            activeStep: 0
                          });
                        }}
                        name={cat.name}
                        key={index}
                      />
                    ))}
                  </ul>
                </div>
              </nav>
            </div>

            {filteredData.map((images, index) => {
              const gallery = images.gallery;
              const techdetais = images.techdata;
              const details = techdetais.details;
              const defaultDetails = techdetais.defaultDetails;
              const engine = defaultDetails.engine;
              const weight = defaultDetails.weight;
              const consumption = defaultDetails.consumption;

              const mobileDetails = details.map((item, index) => (
                <li className="bikes-section__bike-spec-table-item" key={index}>
                  {item.name} <span>{item.value}</span>
                </li>
              ));

              const galleryImages = gallery.map((img, index) => (
                <img src={img.img_url} alt={`Arūnas Gelažninkas - AG dakar ${images.cat_name}`} key={index} className="bikes-section__bike" />
              ));

              const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
                <ArrowForward
                  {...props}
                  className={
                    "slick-next slick-arrow" +
                    (currentSlide === 0 ? " slick-disabled" : "")
                  }
                  aria-hidden="true"
                  aria-disabled={currentSlide === 0 ? true : false}
                />
              );
              const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
                <ArrowBack
                  {...props}
                  className={
                    "slick-prev slick-arrow" +
                    (currentSlide === slideCount - 1 ? " slick-disabled" : "")
                  }
                  aria-hidden="true"
                  aria-disabled={currentSlide === slideCount - 1 ? true : false}
                />
              );

              const settings = {
                dots: true,
                infinite: true,
                arrows: this.state.arrows,
                slidesToShow: 1,
                nextArrow: <SlickArrowRight />,
                prevArrow: <SlickArrowLeft />,
                className: "bikes-section__bike-slider",
              }
              return (
                <div className="bikes-section__bike-container-wrapper" key={index}>
                  <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    spacing={3}
                  >
                    <Grid
                      className="bikes-section__bike-container"
                      ref="bikes-section__bike-container"
                      item
                      xl={7}
                      lg={6}
                      xs={12}
                    >
                      <h3 className="bikes-section__bike-title">
                        {images.name}
                        <span className="bikes-section__bike-subtitle">
                          {images.cat_name}
                        </span>
                      </h3>
                      <div className="bikes-section__bike-images">
                        <Slider {...settings}>
                          {galleryImages}
                        </Slider>
                      </div>
                      <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        spacing={3}
                        className="bikes-section__technical-data-excerpt"
                        ref="bikes-section__technical-data-excerpt"
                      >
                        <Grid
                          item
                          xs={4}
                          className="bikes-section__technical-data-wrapper"
                        >
                          <h3 className="bikes-section__technical-data">
                            {engine.value}
                            <span className="bikes-section__technical-data-unit">
                              {engine.units}
                            </span>
                            <span className="bikes-section__technical-data-title">
                              {engine.name}
                            </span>
                          </h3>
                        </Grid>
                        <Grid
                          item
                          xs={4}
                          className="bikes-section__technical-data-wrapper"
                        >
                          <h3 className="bikes-section__technical-data">
                            {weight.value}
                            <span className="bikes-section__technical-data-unit">
                              {weight.units}
                            </span>
                            <span className="bikes-section__technical-data-title">
                              {weight.name}
                            </span>
                          </h3>
                        </Grid>
                        <Grid
                          item
                          xs={4}
                          className="bikes-section__technical-data-wrapper"
                        >
                          <h3 className="bikes-section__technical-data">
                            {consumption.value}
                            <span className="bikes-section__technical-data-unit">
                              {consumption.units}
                            </span>
                            <span className="bikes-section__technical-data-title">
                              {consumption.name}
                            </span>
                          </h3>
                        </Grid>
                      </Grid>
                      <button
                        className="bikes-section__bike-specification-cta button button--green"
                        onClick={toggleDrawer("right", true)}
                      >
                        {techdetais.title}
                      </button>
                    </Grid>
                    <Grid
                      item
                      xl={5}
                      lg={6}
                      xs={false}
                      className="bikes-section__bike-specification"
                    >
                      <BikeTechData data={techdetais} height={this.state} />
                    </Grid>
                  </Grid>
                  <Drawer
                    className="bikes-section__tech-data-drawer"
                    anchor="right"
                    open={this.state.right}
                    onClose={toggleDrawer("right", false)}
                  >
                    <div
                      className="bikes-section__bike-spec-table-wrapper"
                      role="presentation"
                    >
                      <div className="bikes-section__bike-spec-table-header">
                        <CloseRounded className="close-icon" onClick={toggleDrawer("right", false)} />
                        <h4 className="bikes-section__bike-spec-table-title">
                          {techdetais.title}
                        </h4>
                      </div>
                      <ul className="bikes-section__bike-spec-table">
                        {mobileDetails}
                      </ul>
                    </div>
                  </Drawer>
                </div>
              );
            })}
          </Container>
        </div>
      </section>
    );
  }
}

export default BikesSection;
