import React, { Component } from "react";
import PlayCircleOutlineOutlined from "@material-ui/icons/PlayCircleOutlineOutlined";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import YouTube from "react-youtube";
import CloseRounded from "@material-ui/icons/CloseRounded";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class BioImg extends Component {
  constructor() {
    super();
    this.state = {
      open: false
    };
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.videoEnded = this.videoEnded.bind(this);
  }

  handleClickOpen() {
    this.setState({
      open: true
    });
  }

  handleClose() {
    this.setState({
      open: false
    });
  }

  videoEnded() {
    this.setState({
      open: false
    });
  }

  render() {
    const opts = {
      height: "947",
      width: "1920",
      playerVars: {
        end: 805,
        frameborder: 0,
        autoplay: 1,
        controls: 1,
        modestbranding: 1,
        disablekb: 0,
        rel: 0,
        fs: 1
      }
    };

    return (
      <div className="bioimg__wrapper">
        <img
          className="bioimg"
          src={this.props.videoCover}
          alt="Arūnas Galažninkas"
        />
        <div className="bioimg__cta-wrapper">
          <div className="bioimg__cta">
            <PlayCircleOutlineOutlined
              className="bioimg__cta-icon"
              onClick={this.handleClickOpen}
            />
            <div className="bioimg__cta-text">
              <h3 className="bioimg__cta-title" onClick={this.handleClickOpen}>
                {this.props.cta}
              </h3>
            </div>
          </div>
        </div>
        <Dialog
          fullScreen
          open={this.state.open}
          onClose={this.handleClose}
          TransitionComponent={Transition}
        >
          <div className="biography-video">
            <div className="biography-video__dialog-header">
              <i onClick={this.handleClose} className="icon-wrapper">
                <CloseRounded className="close-icon" />
              </i>
            </div>
            <YouTube
              containerClassName="biography-video__wrapper biography-video__wrapper--fullwidth"
              videoId={`${this.props.videoId}`}
              id="video"
              opts={opts}
              onEnd={this.videoEnded}
            />
          </div>
        </Dialog>
      </div>
    );
  }
}

export default BioImg;
