import React, { Component } from "react";
import Container from "@material-ui/core/Container";
import { ReactComponent as InstagramLogo } from "../../../images/soc-media-logo/instagram-logo.svg";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ModeCommentIcon from "@material-ui/icons/ModeComment";
import Slider from "react-slick";

const pathArray = window.location.pathname.split("/");
const firstPath = pathArray[1];

class InstagramPost extends Component {
  render() {
    const feed = this.props.postdata;
    const date = feed.created_time;
    const cdate = new Date(date * 1000),
      dayNamesLT = [
        "Sau",
        "Vas",
        "Kov",
        "Bal",
        "Geg",
        "Bir",
        "Lie",
        "Rgp",
        "Rgs",
        "Spl",
        "Lap",
        "Grd"
      ],
      dayNamesEN = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"
      ],
      month = firstPath === "en" ? dayNamesEN[cdate.getMonth()] : dayNamesLT[cdate.getMonth()],
      year = cdate.getFullYear(),
      day = cdate.getDate();

    return (
      <div className="instagram-feed__post-card">
        <div className="instagram-feed__post-card-content">
          <a href={feed.postURL} target="_blank" rel="noopener noreferrer">
            <div className="instagram-feed__post-img-wrapper">
              <div className="instagram-feed__post-info-wrapper">
                <div className="instagram-feed_post-info">
                  <div className="instagram-feed_post-info-data">
                    <FavoriteIcon className="instagram-feed_post-info-data-icon" />{" "}
                    {feed.likes}
                  </div>
                  <div className="instagram-feed_post-info-data">
                    <ModeCommentIcon className="instagram-feed_post-info-data-icon" />{" "}
                    {feed.comments}
                  </div>
                </div>
              </div>
              <img
                style={{ width: "100%" }}
                src={feed.thumbnail}
                className="instagram-feed__post-img"
                alt="a"
              />
            </div>
          </a>
          <div className="socialfeed-section__card-header">
            <a href="https://www.instagram.com/gelazninkas.arunas/" target="_blank" rel="noopener noreferrer">
              <InstagramLogo
                height="24px"
                width="24px"
                className="soc-icon instagram-logo"
              />
            </a>
            <p className="socialfeed-section__card-header-date">
              {year} {month} {`${day}`}
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default class InstaFeed extends Component {
  render() {
    const feed = this.props.data;
    const settings = {
      dots: true,
      infinite: false,
      arrows: false,
      autoplay: false,
      autoplaySpeed: 3000,
      slidesToShow: 3,
      rows: 3,
      variableWidth: false,
      className: "socmedia-section__feed-slider",
      responsive: [
        {
          breakpoint: 1280,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            rows: 1,
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            rows: 1,
          }
        },
        {
          breakpoint: 567,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            rows: 1,
          }
        }
      ]
    }
    return (
      <Container className="instafeed-container" maxWidth="lg" >
        <Slider {...settings}>
          {feed.map((item, index) => (
            <InstagramPost postdata={item} key={index} />
          ))}
        </Slider>
      </Container>
    );
  }
}
