import React, { Component } from "react";
import PropTypes from "prop-types";

/**
 * Note :
 * If you're using react v 15.4 or less
 * You can directly import PropTypes from react instead.
 * Refer to this : https://reactjs.org/warnings/dont-call-proptypes.html
 */

class Countdown extends Component {
  constructor(props) {
    super(props);
    this.state = this.calculateCountdown(this.props.date);
  }

  componentDidMount() {
    // update every second
    this.interval = setInterval(() => {
      const date = this.calculateCountdown(this.props.date);
      date ? this.setState(date) : this.stop();
    }, 1000);
  }

  componentWillUnmount() {
    this.stop();
  }

  calculateCountdown(endDate) {
    let diff = (Date.parse(new Date(endDate)) - Date.parse(new Date())) / 1000;

    // clear countdown when date is reached
    if (diff <= 0) return false;

    const timeLeft = {
      years: 0,
      days: 0,
      hours: 0,
      min: 0,
      sec: 0
    };

    // calculate time difference between now and expected date
    if (diff >= 365.25 * 86400) {
      // 365.25 * 24 * 60 * 60
      timeLeft.years = Math.floor(diff / (365.25 * 86400));
      diff -= timeLeft.years * 365.25 * 86400;
    }
    if (diff >= 86400) {
      // 24 * 60 * 60
      timeLeft.days = Math.floor(diff / 86400);
      diff -= timeLeft.days * 86400;
    }
    if (diff >= 3600) {
      // 60 * 60
      timeLeft.hours = Math.floor(diff / 3600);
      diff -= timeLeft.hours * 3600;
    }
    if (diff >= 60) {
      timeLeft.min = Math.floor(diff / 60);
      diff -= timeLeft.min * 60;
    }
    timeLeft.sec = diff;

    return timeLeft;
  }

  stop() {
    clearInterval(this.interval);
  }

  addLeadingZeros(value) {
    value = String(value);
    while (value.length < 2) {
      value = "0" + value;
    }
    return value;
  }

  render() {
    const countDown = this.state;
    const sec = this.addLeadingZeros(countDown.sec).split("");
    const min = this.addLeadingZeros(countDown.min).split("");
    const hours = this.addLeadingZeros(countDown.hours).split("");
    const days = this.addLeadingZeros(countDown.days).split("");

    return (
      <div className="countdown">
        <div className="countdown__col">
          <div className="countdown__element countdown__element--days">
            <p className="countdown__value">
              {days.map((days, index) => (
                <span key={index}>{days}</span>
              ))}
            </p>
            <span className="countdown__label">d.</span>
          </div>
        </div>

        <div className="countdown__col">
          <div className="countdown__element countdown__element--hours">
            <p className="countdown__value">
              {hours.map((hours, index) => (
                <span key={index}>{hours}</span>
              ))}
            </p>
            <span className="countdown__label">val.</span>
          </div>
        </div>

        <div className="countdown__col">
          <div className="countdown__element countdown__element-min">
            <p className="countdown__value">
              {min.map((min, index) => (
                <span key={index}>{min}</span>
              ))}
            </p>
            <span className="countdown__label">min.</span>
          </div>
        </div>

        <div className="countdown__col">
          <div className="countdown__element countdown__element--sec">
            <p className="countdown__value">
              {sec.map((sec, index) => (
                <span key={index}>{sec}</span>
              ))}
            </p>
            <span className="countdown__label">sek.</span>
          </div>
        </div>
      </div>
    );
  }
}

Countdown.propTypes = {
  date: PropTypes.string.isRequired
};

Countdown.defaultProps = {
  date: new Date()
};

export default Countdown;
