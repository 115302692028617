import React, { Component } from "react";
import InstaFeed from "./instagram/InstaFeed";
import FacebookFeed from "./facebook/FacebookFeed";
import YoutubeFeed from "./youtube/YouTubeFeed";
import Container from "@material-ui/core/Container";

const pathArray = window.location.pathname.split("/");
const firstPath = pathArray[1];

class Social extends Component {
  constructor() {
    super();
    this.state = {
      setActive: ""
    };
  }

  render() {
    return (
      <li
        id={this.props.category}
        onClick={this.props.onClick}
        className={`nav__item`}
      >
        <span className="nav__link">
          <a href={this.props.link} target="_blank" rel="noopener noreferrer">
            {this.props.name}
          </a>
        </span>
      </li>
    );
  }
}

class SocialFeed extends Component {
  constructor(props) {
    super(props);
    this.state = {
      socmedia: [
        {
          soc: "Instagram",
          url: "https://www.instagram.com/gelazninkas.arunas/"
        },
        {
          soc: "Facebook",
          url: "https://www.facebook.com/arunas.gelazninkas/"
        },
        {
          soc: "Youtube",
          url: "https://www.youtube.com/channel/UC8HFtm9EVlGwddafi3qON5Q"
        }
      ],
      selectedIndex: 0,
      setIndex: null,
      selectedMedia: "Instagram",
      youtubeData: [],
      instagramData: [],
      facebookData: []
    };
  }

  handleClick(selectedIndex) {
    const selected =
      this.state.selectedIndex === selectedIndex
        ? selectedIndex
        : selectedIndex;
    this.setState({
      selectedIndex: selected
    });
  }

  render() {
    const socmedia = this.state.socmedia;
    const facebook = <FacebookFeed data={this.state.facebookData} />;
    const instagram = <InstaFeed data={this.state.instagramData} />;
    const youtube = <YoutubeFeed data={this.state.youtubeData} />;
    const title =
      firstPath === "en"
        ? "Follow Me On Social Media"
        : "Sekite mane socialiniuose tinkluose";
    return (
      <div className="socmedia-section__wrapper">
        <Container className="socmedia-section__socials" maxWidth="lg">
          <nav className="nav socmedia-section__social-nav">
            <div className="nav__container">
              <h2>{title}</h2>
              <ul className="nav__items">
                {socmedia.map((item, index) => (
                  <Social
                    activeMode={this.state.selectedIndex === index}
                    onClick={() => {
                      this.handleClick(index);
                      this.setState({
                        selectedMedia: item.soc
                      });
                    }}
                    name={item.soc}
                    link={item.url}
                    key={index}
                  />
                ))}
              </ul>
            </div>
          </nav>
        </Container>
        <div className="socmedia-section__content-wrapper">
          {this.state.selectedMedia === "Instagram"
            ? instagram
            : this.state.selectedMedia === "Facebook"
            ? facebook
            : youtube}
        </div>
      </div>
    );
  }
}

export default SocialFeed;
