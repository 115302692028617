import React, { Component } from "react";

class LeftSidebar extends Component {
  render() {
    return (
      <nav className="nav nav--social-media">
        <div className="nav__container">
          <ul className="nav__items">
            <li className="nav__item">
              <a
                className="nav__link"
                href="https://www.youtube.com/channel/UC8HFtm9EVlGwddafi3qON5Q"
                target="_blank"
                rel="noopener noreferrer"
              >
                Youtube
              </a>
            </li>
            <li className="nav__item">
              <a
                className="nav__link"
                href="https://www.instagram.com/gelazninkas.arunas/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Instagram
              </a>
            </li>
            <li className="nav__item">
              <a
                className="nav__link"
                href="https://www.facebook.com/arunas.gelazninkas/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Facebook
              </a>
            </li>
          </ul>
        </div>
      </nav>
    );
  }
}

export default LeftSidebar;
